
	const query = new URLSearchParams(window.location.search);
	const myuid = query.get('myuid');
	const touid = query.get('touid');
	const mynickname = query.get('mynickname');
	const tonickname = query.get('tonickname');
	console.log('mynickname',mynickname)
	
	import { defineComponent, reactive, watchEffect, toRefs, ref,getCurrentInstance } from 'vue';

	const Custom = defineComponent({
		props: {
			show: {
				type: Boolean,
				default: () => false,
			},
		},
		setup(props : any, ctx : any) {
			
			const value1 = ref<[Date, Date]>([
				new Date(2000, 10, 10, 10, 10),
				new Date(2000, 10, 11, 10, 10),
			])
			const value2 = ref('')
			
			const shortcuts = [
				{
					text: 'Last week',
					value: () => {
						const end = new Date()
						const start = new Date()
						start.setDate(start.getDate() - 7)
						return [start, end]
					},
				},
				{
					text: 'Last month',
					value: () => {
						const end = new Date()
						const start = new Date()
						start.setMonth(start.getMonth() - 1)
						return [start, end]
					},
				},
				{
					text: 'Last 3 months',
					value: () => {
						const end = new Date()
						const start = new Date()
						start.setMonth(start.getMonth() - 3)
						return [start, end]
					},
				},
			]
			
			
			const data = reactive({
				show: false,
				custom: {
					data: {
						businessID: "text_link",
						text: "欢迎加入腾讯云IM大家庭",
						link: "xxx",
						content:'',
						myuid: myuid,
						touid:touid,
						tonickname:tonickname,
						linkMsgType:'contact',
						applyId:'',
						isReplay:2
					},
					description: '超文本链接',
					extension: '超文本链接'
				},
				myuid: myuid,
				touid: touid,
				mynickname:mynickname,
				tonickname:tonickname,
				TUIServer: null,
			});

			data.TUIServer = Custom.TUIServer;
			
			if (!data.touid) {
				data.touid = Custom.TUIServer.store.conversation.userProfile.userID
			}
			
			if(!data.tonickname){
				data.tonickname = Custom.TUIServer.store.conversation.userProfile.nick
			}

			watchEffect(() => {
				data.show = props.show;
			});

			const toggleShow = () => {
				data.show = !data.show;
			};

			const cancel = () => {
				data.custom = {
					data: {
						businessID: "",
						text: "",
						link: "",
						content: "",
						myuid: "",
						touid: "",
						linkMsgType:'contact',
						tonickname:data.tonickname,
						applyId:'',
						isReplay:2
					},
					// data:'',
					description: '',
					extension: '',
				};
				toggleShow();
			};
			
			//通过getCurrentInstance获取axios
			const { proxy } = getCurrentInstance() as any;
			
			const submit = () => {
				console.log(data.custom);
				proxy.$http.get(`/index.php/Home/CompanyUser/companyInvite?uid=${data.touid}&invite_uid=${data.myuid}`).then((res:any)=>{
					data.custom = {
						data: {
							businessID: "text_link",
							text: "联系方式",
							link: "xxx",
							content:`${data.mynickname}希望获取您的联系方式，是否将手机号码发送给对方？`,
							myuid: data.myuid,
							touid: data.touid,
							tonickname:data.tonickname,
							linkMsgType:'contact',
							applyId:res.data.data.message,
							isReplay:2
						},
						description: '',
						extension: '',
					};
					Custom.TUIServer.sendCustomMessage(data.custom);
					cancel();
				})
				
			};
			
			return {
				...toRefs(data),
				toggleShow,
				cancel,
				submit,
				value1,
				value2,
				shortcuts
			};
		},
	});
	export default Custom;
