
	import { defineComponent, watchEffect, reactive, toRefs, getCurrentInstance, onMounted } from 'vue';
	import { isUrl, JSONToObject } from '../utils/utils';
	import constant from '../../constant';
	import { useStore } from 'vuex';

	import { ElMessage } from 'element-plus'

	const msgCus = defineComponent({
		props: {
			data: {
				type: Object,
				default: () => ({}),
			},
			touid: {
				type: String,
				default: ''
			},
			myuid: {
				type: String,
				default: ''
			},
			interviewList: {
				type: Array,
				default: () => [],
			},
			contactList: {
				type: Array,
				default: () => [],
			},
			companyEntryInviteList: {
				type: Array,
				default: () => [],
			},
			companyInfo: {
				type: Object,
				default: () => ({}),
			},
			parentMethod: Function
		},
		setup(props : any, ctx : any) {
			const VuexStore = ((window as any)?.TUIKitTUICore?.isOfficial && useStore && useStore()) || {};
			const data = reactive({
				data: {} as any,
				extension: {},
				isCustom: '',
				constant: constant,
				hhhh: {},
				interviewList: [],
				contactList: []
			});
			watchEffect(() => {
				data.data = props.data;
				const {
					message: { payload },
				} = props.data;
				data.isCustom = payload.data || ' ';
				data.isCustom = JSONToObject(payload.data);
				if (payload.data === constant.typeService) {
					data.extension = JSONToObject(payload.extension);
				}
				console.log('companyEntryInviteList', props.companyEntryInviteList)
			});

			const openLink = (url : any) => {
				window.open(url);
			};

			const handleCallMessageIcon = () => {
				const callType = JSON.parse(JSON.parse(data?.data?.message?.payload?.data)?.data)?.call_type;
				let className = '';
				switch (callType) {
					case 1:
						className = 'icon-call-voice';
						break;
					case 2:
						className = 'icon-call-video';
						break;
					default:
						break;
				}
				return className;
			};

			const handleCallAgain = async () => {
				const callType = JSON.parse(JSON.parse(props?.data?.message?.payload?.data)?.data)?.call_type;
				switch (data?.data?.message?.conversationType) {
					case (window as any).TUIKitTUICore.TIM.TYPES.CONV_C2C:
						// eslint-disable-next-line no-case-declarations
						const { flow, to, from } = data?.data?.message;
						if (to === from) break;
						try {
							await (window as any)?.TUIKitTUICore?.TUIServer?.TUICallKit?.call({
								userID: flow === 'out' ? to : from,
								type: callType,
							});
							(window as any)?.TUIKitTUICore?.isOfficial && VuexStore?.commit && VuexStore?.commit('handleTask', 6);
						} catch (error) {
							console.warn(error);
						}
						break;
					case (window as any).TUIKitTUICore.TIM.TYPES.CONV_GROUP:
						break;
					default:
						break;
				}
			};
			//通过getCurrentInstance获取axios
			const { proxy } = getCurrentInstance() as any;

			const getStatus = (item : any) => {

				let list = props.interviewList;

				let info = list.find((ele : any) => ele.id == item);

				if (info && info.status) {

					return info.status

				} else {

					return null

				}
			};

			const getPhoneStatus = (item : any) => {

				let list = props.contactList;

				let info = list.find((ele : any) => {

					return ele.id == item

				});

				if (info && info.status) {

					return info

				} else {

					return null

				}
			};

			// 在职状态：1入职，2：待解绑，3已离职,4待确认入职,5拒绝入职,6超时失效
			const getEntryStatus = (item : any) => {

				let list = props.companyEntryInviteList;

				let info = list.find((ele : any) => {

					return ele.id == item

				});

				if (info && info.status) {

					return info.status

				} else {

					return null

				}
			};

			// 接受面试邀请
			const accept = async (interview_id : any) => {
				await proxy.$http.get(`/index.php/Home/CompanyUser/userAgreeInterview?interview_id=${interview_id}`)
			}

			// 拒绝面试邀请
			const refuse = async (interview_id : any) => {
				await proxy.$http.get(`/index.php/Home/CompanyUser/userRefuseInterview?uid=${props.myuid}&interview_id=${interview_id}`)
			}

			//同意企业获取联系方式
			const agreeGivePhone = async (id : any) => {
				props.parentMethod(id)
				await proxy.$http.get(`/index.php/Home/CompanyUser/agreeUserData?uid=${props.myuid}&invite_uid=${props.touid}&id=${id}`)
			}

			//拒绝企业获取联系方式
			const refuseGivePhone = async (id : any) => {
				await proxy.$http.get(`/index.php/Home/CompanyUser/refuseUserData?uid=${props.myuid}&invite_uid=${props.touid}&id=${id}`)
			}

			//接受入职邀请
			const agreeEntry = async (id : any) => {

				await proxy.$http.get(`/index.php/Home/CompanyUser/userAgreeInvite?uid=${props.myuid}&comapny_user_id=${props.touid}&id=${id}`).then((res : any) => {
					console.log(res.data.data.message)
					if (res.data.data.message != '加入成功') {
						ElMessage.error(res.data.data.message)
					}

				})
			}

			//拒绝入职邀请
			const refuseEntry = async (id : any) => {
				await proxy.$http.get(`/index.php/Home/CompanyUser/userRefuseInvite?uid=${props.myuid}&comapny_user_id=${props.touid}&id=${id}`)
			}


			return {
				...toRefs(data),
				isUrl,
				openLink,
				handleCallMessageIcon,
				handleCallAgain,
				accept,
				refuse,
				getStatus,
				getPhoneStatus,
				getEntryStatus,
				agreeGivePhone,
				agreeEntry,
				refuseEntry,
				refuseGivePhone
			};
		},
	});
	export default msgCus;
