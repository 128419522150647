import axios, { AxiosInstance } from 'axios';

const instance: AxiosInstance = axios.create({
  // baseURL: 'https://test.vitadviser.com', // 设置基础 URL
  baseURL: 'https://vitadviser.com', // 设置基础 URL
  timeout: 5000, // 设置请求超时时间
  headers: {
    'Content-Type': 'application/json', // 设置请求头
  },
});

export default instance;