
	const query = new URLSearchParams(window.location.search);
	const myuid = query.get('myuid');
	const touid = query.get('touid');
	const mynickname = query.get('mynickname');
	const tonickname = query.get('tonickname');
	console.log('mynickname', mynickname)

	import { ElMessage } from 'element-plus'

	import { defineComponent, reactive, watchEffect, toRefs, ref, getCurrentInstance } from 'vue';

	const Custom = defineComponent({
		props: {
			show: {
				type: Boolean,
				default: () => false,
			},
		},
		setup(props : any, ctx : any) {

			//通过getCurrentInstance获取axios
			const { proxy } = getCurrentInstance() as any;

			const size = ref<'' | 'large' | 'small'>('');

			const data = reactive({
				show: false,
				custom: {
					data: {
						businessID: "text_link",
						text: "欢迎加入腾讯云IM大家庭",
						link: "xxx",
						content: '',
						myuid: myuid,
						touid: touid,
						tonickname: tonickname,
						linkMsgType: 'entry',
						applyId: '',
						structure:''
					},
					description: '超文本链接',
					extension: '超文本链接'
				},
				myuid: myuid,
				touid: touid,
				mynickname: mynickname,
				tonickname: tonickname,
				TUIServer: null,
				structureValue: '请选择',
				structureName:'',
				structureList: [],
				dateValue: ''
			});

			data.TUIServer = Custom.TUIServer;

			if (!data.touid) {
				data.touid = Custom.TUIServer.store.conversation.userProfile.userID
			}

			if (!data.tonickname) {
				data.tonickname = Custom.TUIServer.store.conversation.userProfile.nick
			}

			watchEffect(() => {
				data.show = props.show;
			});

			const toggleShow = () => {
				data.show = !data.show;
			};

			const cancel = () => {
				data.custom = {
					data: {
						businessID: "",
						text: "",
						link: "",
						content: "",
						myuid: "",
						touid: "",
						linkMsgType: 'entry',
						tonickname: data.tonickname,
						applyId: '',
						structure:data.structureValue
					},
					// data:'',
					description: '',
					extension: '',
				};
				toggleShow();
			};

			const defaultProps = {
				children: 'child',
				label: 'name',
				value: 'id'
			}

			const companyStruList = () => {
				proxy.$http.get(`/index.php/Home/Company/companyStruList?uid=${data.myuid}`).then((res : any) => {
					data.structureList = res.data.data.fatherData;
				})
			}
			companyStruList()

			const submit = () => {
				if (data.structureValue == '' || data.structureValue == '请选择') {
					ElMessage.error('请选择入职部门！')
					return
				}
				if (data.dateValue == '') {
					ElMessage.error('请选择最晚确认时间！')
					return
				}
				const join_last_time = timeFilter(data.dateValue)
				proxy.$http.get(`/index.php/Home/CompanyUser/inviteEntry?invite_uid=${data.touid}&stru_id=${data.structureValue}&join_last_time=${join_last_time}`).then((res : any) => {
					data.custom = {
						data: {
							businessID: "text_link",
							text: "入职邀请",
							link: "xxx",
							content: `${data.mynickname}正在邀请您加入，${data.tonickname}将入职${data.structureValue}`,
							myuid: data.myuid,
							touid: data.touid,
							tonickname: data.tonickname,
							linkMsgType: 'entry',
							applyId: res.data.data.message,
							structure:data.structureName
						},
						description: '',
						extension: '',
					};
					Custom.TUIServer.sendCustomMessage(data.custom);
					cancel();
				})

			};

			const timeFilter = (timeStr : any) => {
				const day = timeStr.getDate().toString().padStart(2, '0');
				const month = (timeStr.getMonth() + 1).toString().padStart(2, '0'); // 月份是从0开始的
				const year = timeStr.getFullYear();
				const hours = timeStr.getHours().toString().padStart(2, '0');
				const minutes = timeStr.getMinutes().toString().padStart(2, '0');
				const seconds = timeStr.getSeconds().toString().padStart(2, '0');
				return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
			}
			
			const currentChange = (e:any)=>{
				data.structureName = e.name
			}

			return {
				...toRefs(data),
				toggleShow,
				cancel,
				submit,
				timeFilter,
				size,
				companyStruList,
				defaultProps,
				currentChange
			};
		},
	});
	export default Custom;
